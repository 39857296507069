<template>
  <div class='teacher'>
    <list-template :current-page='page' :loading="loading" :table-config='tableConfig' :table-data='tableData' :total='total' @onChangePage='changeCurrentPage' @onExportData="onExportData" @onHandle='tableHandle'>
      <div slot='title' style='display: flex;justify-content: space-between;padding-right: 24rem'>
        <!--        <el-button size='mini' style='height:32rem' type='primary' @click='exportData'>导出数据</el-button>-->
        <searchBlock :search-config='searchConfig' @onReset='onReset' @onSearch='onSearch'/>
      </div>
    </list-template>
  </div>
</template>
<script>
import searchBlock from '@/components/SearchBlock.vue';
import {mapState} from 'vuex';
import tools from '@/utils/tools'

export default {
  _config:{
    route:{
      path:"list",
      meta:{"title":"列表","keepAlive":true},

    }
  },
  components: { // 引入组件使用
    searchBlock,
  },

  data() { // 这里存放数据
    return {
      loading:true,
      regist_date: '',
      total: 0, // 总数
      search: {}, // 搜索条件
      tableData: [], // 表格中的数据
      tableConfig: [{ // 表格配置
        prop: 'name',
        label: '家长姓名',
      }, {
        prop: 'mobile',
        label: '注册手机号',
      }, {
        prop: 'student_name',
        label: '学生姓名',
      }, {
        prop: 'relationship',
        label: '家长与学生关系',
      }, {
        prop: 'school_name',
        label: '咨询学校',
      }, {
        prop: 'consulting_teacher',
        label: '咨询老师',
      }, {
        prop: 'recruit_status',
        label: '招收状态',
      }, {
        prop: 'created_at',
        label: '注册时间',
        width: 200,
      }, {
        label: '操作',
        handle: true,
        render: () => ['查看']
      }],

      searchConfig: [{ // 表格搜索配置
        prop: 'regist_date',
        tag: 'datePicker',
        property: {
          size: 'small',
          style: 'margin-right:20rem;',
          valueFormat: 'yyyy-MM-dd',
          type: 'daterange',
          rangeSeparator: '至',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
        },
      }],

    };
  },

  mounted() { // 生命周期 - 挂载完成（可以访问 DOM 元素）
    this.$store.commit('setPage', 1);
    this.getData();
  },

  activated() { // 如果页面有 keep-alive 缓存功能,这个函数会触发  进入的时候触发
    this.getData();
  },

  computed: { // 计算属性 类似于 data 概念
    ...mapState(['page']),
  },

  methods: { // 方法合集
    onExportData() { // 导出数据
      let params = {};
      if (this.search.regist_date) params = { regist_date: this.search.regist_date[0] + ' ~ ' + this.search.regist_date[1] };
      this.$_register.get('/api/recruit/account-manage/export-account-clues', { params, responseType: 'blob' }).then(res => {
        tools.download('预报名线索池', res.data)
      });
    },

    changeCurrentPage(e) { // 分页被更改
      this.$store.commit('setPage', e);
      this.getData();
    },

    getData() { // 获取数据
      let { search, page, regist_date } = this;
      if (JSON.stringify(search) !== '{}') regist_date = search.regist_date[0] + ' ~ ' + search.regist_date[1];
      this.loading = true;
      this.$_register.get('api/recruit/account-manage/get-account-clues', { params: { regist_date, page } }).then(res => {
        if (res.data.data.list) {
          this.tableData = res.data.data.list;
          this.total = res.data.data.page.total;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      }).finally(()=>this.loading = false);
    },

    onSearch(val) { // 搜索按钮被点击
      this.$store.commit('setPage', 1);
      this.search = val;
      this.getData();
    },

    onReset() {
      this.regist_date = '';
    },

    tableHandle(row, text, index) { // 表格后面操作被点击
      this.$router.push('./look?id=' + row.id)
    },
  },
};
</script>
<style scoped>
</style>
